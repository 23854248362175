import {
    BehaviorSubject,
    EMPTY,
    Observable,
    catchError,
    combineLatest,
    firstValueFrom,
    iif,
    map,
    of,
    shareReplay,
    throwError
} from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    startWith,
    switchMap,
    take,
    tap,
    timeout,
    withLatestFrom
} from 'rxjs/operators';

import { DatePipe } from '@angular/common';
import {
    HttpClient,
    HttpErrorResponse,
    HttpParams
} from '@angular/common/http';
import { Injectable, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { translate } from '@jsverse/transloco';
import { ConfigModel } from '@mhp-immersive-exp/contracts/src/configuration/config-model.interface';
import {
    Opportunity,
    SalesforceOpportunityHandlingDto
} from '@mhp/aml-shared/data-proxy/salesforce-opportunity-handling.interface';
import { getModelCodeForDerivateCode } from '@mhp/aml-shared/derivate-mapping/derivate-mapping';
import { ConfigurationResponse } from '@mhp/aml-shared/infor/interfaces/configuration-response.interface';
import { IllegalStateError } from '@mhp/common';
import { UiMatDialogService, UiNotificationService } from '@mhp/ui-components';
import {
    ErrorHandlerService,
    I18nService,
    L10nService,
    SerializerService
} from '@mhp/ui-shared-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { environment } from '../../environments/environment';
import { ROUTE_ORDER_EDIT, ROUTE_ORDER_MANAGEMENT } from '../app-route-names';
import { BACKDROP_CLASS_BLURRY } from '../common/dialog/dialog.constants';
import { ExtendedUiOptionCode } from '../configuration/configuration-model/configuration-interfaces';
import { CpqSessionHandlerService } from '../configuration/cpq/cpq-session-handler.service';
import { isExtendedUiOptionCode } from '../configuration/services/configuration-helper';
import { ConfigurationNodeLookupService } from '../configuration/services/configuration-node-lookup.service';
import { ProductConfigurationSessionService } from '../configuration/services/product-configuration-session.service';
import {
    CompleteLocationSessionData,
    ConfigurationSessionInfoService
} from '../configuration/session-info/configuration-session-info.service';
import { PricingService } from '../dealer/pricing/pricing.service';
import { LabelHelperService } from '../i18n/label-helper.service';
import { AmlProductDataService } from '../product-data/aml-product-data-service';
import { ProductInfo } from '../product-data/aml-product-data-strategy.interface';
import {
    OrderError,
    OrderNewVehicleError,
    UpdateOrderError
} from './errors/order-management.errors';
import { FormFieldDialogComponent } from './form-field-dialog/form-field-dialog.component';
import {
    DialogResult,
    OrderConfiguration,
    OrderPriority,
    PricingOptions,
    SavedConfiguration,
    SavedConfigurationPage
} from './model/order-management.models';
import {
    toOrderConfiguration,
    toOrderPriority
} from './order-management.service.helper';
import { PricingDialogComponent } from './pricing-dialog/pricing-dialog.component';
import { SaveConfigurationDialogComponent } from './save-configuration-dialog/save-configuration-dialog.component';

const PRICING_DIALOG_DEFAULTS = Object.freeze({
    backdropClass: BACKDROP_CLASS_BLURRY,
    width: '400px'
});

const SAVE_CONFIG_DIALOG_DEFAULTS = Object.freeze({
    backdropClass: BACKDROP_CLASS_BLURRY,
    width: '600px'
});

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class OrderManagementService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly dialogService: UiMatDialogService,
        private readonly uiNotificationService: UiNotificationService,
        private readonly serializerService: SerializerService,
        private readonly labelHelperService: LabelHelperService,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        private readonly datepipe: DatePipe,
        private readonly cpqSessionHandlerService: CpqSessionHandlerService,
        private readonly router: Router,
        private readonly productConfigurationSessionService: ProductConfigurationSessionService,
        private readonly nodeLookupService: ConfigurationNodeLookupService,
        private readonly l10nService: L10nService,
        private readonly i18nService: I18nService,
        private readonly pricingService: PricingService,
        private readonly productDataService: AmlProductDataService
    ) {
        this.initCatchOpportunityIdFromUrl();
    }

    private readonly existingConfigSource: BehaviorSubject<
        OrderConfiguration | undefined
    > = new BehaviorSubject(undefined);

    private readonly existingModelCodeSource: BehaviorSubject<string> =
        new BehaviorSubject('');

    private readonly existingConfigDetailIdSource: BehaviorSubject<string> =
        new BehaviorSubject('');

    private readonly existingOpportunityIdSource = new BehaviorSubject<
        string | undefined
    >(undefined);

    setExistingConfig$(config: OrderConfiguration) {
        this.existingConfigSource.next(config);
    }

    resetExistingConfig() {
        this.existingConfigSource.next(undefined);
    }

    getExisitingConfig(): OrderConfiguration | undefined {
        return this.existingConfigSource.getValue();
    }

    setExistingModelCode(code: string): void {
        this.existingModelCodeSource.next(code);
    }

    resetExistingModelCode(): void {
        this.existingModelCodeSource.next('');
    }

    getExistingModelCode(): string {
        return this.existingModelCodeSource.getValue();
    }

    setExistingConfigDetailId(id: string) {
        this.existingConfigDetailIdSource.next(id);
    }

    resetExistingConfigDetailId(): void {
        this.existingConfigDetailIdSource.next('');
    }

    getExistingConfigDetailsId(): string {
        return this.existingConfigDetailIdSource.getValue();
    }

    resetExistingOpportunityId(): void {
        this.existingOpportunityIdSource.next(undefined);
    }

    getExistingOpportunityId(): string | undefined {
        return this.existingOpportunityIdSource.value;
    }

    /**
     * Resets the configuration state, namely:
     * - existingConfig
     * - existingModelCode
     * - existingConfigDetailId
     * - existingOpportunityId
     */
    resetConfigurationState() {
        this.resetExistingConfig();
        this.resetExistingModelCode();
        this.resetExistingConfigDetailId();
        this.resetExistingOpportunityId();
    }

    getOrderPriority$(): Observable<OrderPriority[]> {
        return this.httpClient
            .get<
                string[]
            >(`${environment.appConfig.dataProxy.url}/api/ion/list-order-priority`)
            .pipe(
                this.errorHandlerService.applyRetryWithHintsOnError(() =>
                    translate('ORDER_MANAGEMENT.ERROR.NO_ORDER_PRIORITY')
                ),
                map(toOrderPriority)
            );
    }

    getCPQModelCodes$(): Observable<string[]> {
        return this.httpClient
            .get<
                string[]
            >(`${environment.appConfig.dataProxy.url}/api/cpq/get-cpq-model-codes`)
            .pipe(
                shareReplay(1),
                this.errorHandlerService.applyRetryWithHintsOnError(() =>
                    translate('ORDER_MANAGEMENT.ERROR.NO_CPQ_MODEL_CODES')
                )
            );
    }

    getOpportunityList$(recordType = 'New_Vehicle'): Observable<Opportunity[]> {
        return this.httpClient
            .get<SalesforceOpportunityHandlingDto>(
                `${environment.appConfig.dataProxy.url}/api/salesforce/opportunity?recordType=${recordType}`
            )
            .pipe(
                this.errorHandlerService.applyRetryWithHintsOnError(() =>
                    translate('ORDER_MANAGEMENT.ERROR.NO_OPPORTUNITIES')
                ),
                map((response) => response.opportunity)
            );
    }

    getOpportunityDetails$(
        opportunityId: string,
        recordType = 'New_Vehicle'
    ): Observable<Opportunity> {
        return this.httpClient
            .get<{
                opportunity: Opportunity;
            }>(
                `${environment.appConfig.dataProxy.url}/api/salesforce/opportunity/${opportunityId}?recordType=${recordType}`
            )
            .pipe(
                this.errorHandlerService.applyRetry(),
                map((response) => response.opportunity)
            );
    }

    getExistingSalesOrderConfig$(salesOrderId: string, isInEditMode: boolean) {
        this.uiNotificationService.showNotification(
            translate('ORDER_MANAGEMENT.NOTIFICATION.LOAD_EXISTING_SALES_ORDER')
        );
        return this.httpClient
            .post(
                `${environment.appConfig.dataProxy.url}/api/cpq/start-configuration-sales-order`,
                { salesOrder: salesOrderId, editOrder: isInEditMode }
            )
            .pipe(
                this.errorHandlerService.applyRetry({
                    messageProviderOnFinalError: (error) => {
                        if ((error as any).error?.message) {
                            return translate(
                                'ORDER_MANAGEMENT.ERROR.NO_EXISTING_ORDER_DETAIL',
                                {
                                    msg: (error as any).error.message
                                }
                            );
                        }
                        return translate(
                            'ORDER_MANAGEMENT.ERROR.NO_EXISTING_ORDER'
                        );
                    }
                }),
                toOrderConfiguration(this.productDataService)
            );
    }

    /**
     *
     * @param modelCode The CPQ internal model-code, e.g. M200
     * @param country The country to be used for the configuration
     * @param selectedAccessoriesCodes Optional: The selected accessories codes
     * @param sourceSessionId The id to re-build a configuration on (e.g. configurationDetailId)
     */
    postStartConfiguration(
        modelCode: string,
        country: string | undefined,
        selectedAccessoriesCodes?: string[],
        sourceSessionId?: string
    ): Observable<OrderConfiguration> {
        return combineLatest([
            iif(
                () => !!selectedAccessoriesCodes,
                of(selectedAccessoriesCodes),
                this.getSelectedAccessories$()
            ),
            this.uiContextHasAuthorizedOnlyOptionsSelected$()
        ]).pipe(
            switchMap(([selectedAccessoryCodes, uiContextHasQOptions]) =>
                this.httpClient
                    .post(
                        `${environment.appConfig.dataProxy.url}/api/cpq/start-configuration`,
                        {
                            cpqModelCode: modelCode,
                            countryISOCode: country,
                            selectedAccessoryCodes,
                            sourceSessionId
                        }
                    )
                    .pipe(
                        toOrderConfiguration(this.productDataService),
                        this.errorHandlerService.applyRetry(),
                        catchError(() => EMPTY)
                    )
            )
        );
    }

    /**
     * Push a configuration-change to the given session and get the updated
     * OrderConfiguration as response.
     *
     * @param modelCode The CPQ model code, e.g. M200
     * @param sessionId The session id to apply the change to
     * @param selectionId The ID of the changed option
     * @param selectionValue The value of the changed option
     * @param selectedAccessoryCodes All selected accessories-codes
     */
    postConfigure$(
        modelCode: string | undefined,
        sessionId: string,
        selectionId?: string,
        selectionValue?: string,
        selectedAccessoryCodes?: string[]
    ): Observable<OrderConfiguration> {
        const selectedAccessories$ = iif(
            () => !!selectedAccessoryCodes,
            of(selectedAccessoryCodes),
            this.getSelectedAccessories$()
        );

        return selectedAccessories$.pipe(
            switchMap((selectedAccessories: string[]) =>
                this.httpClient
                    .post(
                        `${environment.appConfig.dataProxy.url}/api/cpq/configure`,
                        {
                            sessionID: sessionId,
                            selections: selectionId
                                ? [
                                      {
                                          ID: selectionId,
                                          Value: selectionValue ?? ''
                                      }
                                  ]
                                : [],
                            cpqModelCode: modelCode,
                            selectedAccessoryCodes: selectedAccessories
                        }
                    )
                    .pipe(
                        toOrderConfiguration(this.productDataService),
                        this.errorHandlerService.applyRetry(),
                        catchError((err: unknown) => {
                            if (err instanceof HttpErrorResponse) {
                                const responseBody = err.error;
                                if (
                                    OrderError.isConfigurationResponse(
                                        responseBody
                                    )
                                ) {
                                    return throwError(
                                        () =>
                                            new UpdateOrderError(
                                                responseBody.errorMessage ??
                                                    err.message,
                                                responseBody,
                                                err
                                            )
                                    );
                                }
                                if (OrderError.hasErrorMessage(responseBody)) {
                                    return throwError(
                                        () =>
                                            new UpdateOrderError(
                                                responseBody.errorMessage,
                                                undefined,
                                                err
                                            )
                                    );
                                }
                            }
                            return throwError(
                                () =>
                                    new UpdateOrderError(
                                        translate(
                                            'ORDER_MANAGEMENT.ERROR.UPDATE_ORDER'
                                        ),
                                        undefined,
                                        err
                                    )
                            );
                        })
                    )
            )
        );
    }

    /**
     *
     * @param sessionId The sessionId for the ongoing configuration session.
     * @param configurationDetailId The configurationDetailId as obtained from a previous request to the ruler (infor-session)
     * @param modelCode The modelCode as used internally on LN side (e.g. M200)
     * @param accessories The list of accessories-codes to be used.
     * @param opportunityId The oppurtunity-id to be used for this order.
     * @param orderPriority The order-priority to be used.
     * @param countryCode The country-code which applies to this order.
     * @param orderId An optional order-id to identify an already existing order to be updated.
     * @param numberOfCopies Define the number of copies to be created for this order. Defaults to 1. Note that numbers > 1 are only allowed
     *                       for confidential dealers.
     */
    createNewOrder(
        sessionId: string,
        configurationDetailId: string | undefined,
        modelCode: string,
        accessories: string[],
        opportunityId: string,
        orderPriority: OrderPriority,
        countryCode: string,
        orderId?: string,
        numberOfCopies?: number
    ): Observable<unknown> {
        return this.httpClient
            .post(
                `${environment.appConfig.dataProxy.url}/api/ion/order-new-vehicle`,

                {
                    accessories,
                    modelCode,
                    sessionId,
                    configurationDetailId,
                    opportunityId,
                    orderPriority,
                    countryCode,
                    orderId,
                    numberOfCopies
                }
            )
            .pipe(
                catchError((err) => {
                    if (!(err instanceof HttpErrorResponse)) {
                        return throwError(
                            () =>
                                new OrderNewVehicleError(
                                    'Failed creating new order.',
                                    err
                                )
                        );
                    }

                    const errorBody: ConfigurationResponse = err.error;

                    if (!errorBody) {
                        return throwError(
                            () =>
                                new OrderNewVehicleError(
                                    'Failed creating new order.'
                                )
                        );
                    }

                    return throwError(
                        () =>
                            new OrderNewVehicleError(
                                errorBody.errorMessage ??
                                    'Failed creating new order.',
                                errorBody
                            )
                    );
                })
            );
    }

    openFormFieldDialog$(
        configData: OrderConfiguration,
        formGroup: FormGroup,
        viewContainerRef: ViewContainerRef
    ): Observable<DialogResult> {
        return this.dialogService
            .open$(FormFieldDialogComponent, {
                ...SAVE_CONFIG_DIALOG_DEFAULTS,
                viewContainerRef
            })
            .pipe(
                switchMap(({ componentInstance }) => {
                    componentInstance.configData = configData;
                    componentInstance.orderFormGroup = formGroup;
                    return componentInstance.confirm.pipe(
                        map((dialogResult): DialogResult => dialogResult)
                    );
                }),
                take(1)
            );
    }

    openPricingDialog$(
        showPricingDNP: boolean,
        showPricingRRP: boolean,
        configurationCountry: string,
        productId: string | undefined,
        viewContainerRef: ViewContainerRef
    ): Observable<PricingOptions> {
        return combineLatest([
            this.dialogService.open$(PricingDialogComponent, {
                ...PRICING_DIALOG_DEFAULTS,
                viewContainerRef
            }),
            this.pricingService
                .getAvailablePricingTypesUsingReferenceCountry$(
                    productId,
                    configurationCountry
                )
                .pipe(take(1))
        ]).pipe(
            switchMap(([{ componentInstance }, availablePricingTypes]) => {
                componentInstance.pricingDNPAvailable =
                    availablePricingTypes.includes('DNP');
                componentInstance.pricingRRPAvailable =
                    availablePricingTypes.includes('RRP');
                componentInstance.showPricingDNP = showPricingDNP;
                componentInstance.showPricingRRP = showPricingRRP;
                return componentInstance.confirm.pipe(
                    map((dialogResult): PricingOptions => dialogResult)
                );
            }),
            take(1)
        );
    }

    openSaveConfigurationDialog$(viewContainerRef: ViewContainerRef) {
        return combineLatest([
            this.configurationSessionInfoService.getActiveProductId$(),
            this.configurationSessionInfoService.getCurrentConfigurationUrl$()
        ]).pipe(
            take(1),
            switchMap(([model, configUrl]) => {
                if (!model || !configUrl) {
                    throw new IllegalStateError(
                        'Unable to get product id and/or configuration url'
                    );
                }
                return this.dialogService
                    .open$(SaveConfigurationDialogComponent, {
                        ...SAVE_CONFIG_DIALOG_DEFAULTS,
                        viewContainerRef
                    })
                    .pipe(
                        switchMap(({ componentInstance }) => {
                            componentInstance.isOverwrite = false;
                            return componentInstance.confirm.pipe(
                                take(1),
                                map((configurationTitle) => {
                                    this.saveConfiguration$(
                                        configurationTitle,
                                        model,
                                        configUrl
                                    ).subscribe({
                                        next: () => {
                                            this.uiNotificationService.showAutohideNotification(
                                                translate(
                                                    'ORDER_MANAGEMENT.NOTIFICATION.SAVE_SUCCESS'
                                                )
                                            );
                                        },
                                        error: ({ error }) => {
                                            if (
                                                error.message.includes(
                                                    'already exists'
                                                )
                                            ) {
                                                this.displayOverwriteDialog$(
                                                    viewContainerRef,
                                                    configurationTitle,
                                                    model,
                                                    configUrl
                                                ).subscribe();
                                            } else {
                                                this.uiNotificationService.showError(
                                                    `Error: ${error.message}. Please try again.`
                                                );
                                            }
                                        }
                                    });
                                })
                            );
                        })
                    );
            })
        );
    }

    displayOverwriteDialog$(
        viewContainerRef: ViewContainerRef,
        configurationTitle: string,
        model: string,
        configUrl: string
    ) {
        return this.dialogService
            .open$(SaveConfigurationDialogComponent, {
                ...SAVE_CONFIG_DIALOG_DEFAULTS,
                viewContainerRef
            })
            .pipe(
                switchMap(({ componentInstance }) => {
                    componentInstance.isOverwrite = true;
                    componentInstance.configurationTitle = configurationTitle;
                    return componentInstance.confirm.pipe(
                        // TODO typing when interface available
                        switchMap((configurationTitleResult): any => {
                            this.updateSaveConfiguration$(
                                configurationTitleResult,
                                model,
                                configUrl
                            ).subscribe({
                                next: () => {
                                    this.uiNotificationService.showAutohideNotification(
                                        translate(
                                            'ORDER_MANAGEMENT.NOTIFICATION.OVERWRITE_SUCCESS'
                                        )
                                    );
                                },
                                error: ({ statusText }) => {
                                    this.uiNotificationService.showError(
                                        `Error: ${statusText}. Please try again.`
                                    );
                                }
                            });
                        })
                    );
                }),
                take(1)
            );
    }

    // TODO typing when interface available
    saveConfiguration$(
        title: string,
        model: string,
        configUrl: string
    ): Observable<unknown> {
        return this.httpClient
            .post(
                `${environment.appConfig.dataProxy.url}/api/configuration-db/add-dealer-configuration`,
                {
                    configurationName: title,
                    model,
                    configurationUrl: configUrl
                }
            )
            .pipe(
                catchError((err) => {
                    console.error(err);
                    return throwError(() => err);
                })
            );
    }

    // TODO typing when interface available
    updateSaveConfiguration$(
        title: string,
        model: string,
        configUrl: string
    ): Observable<unknown> {
        return this.httpClient
            .put(
                `${environment.appConfig.dataProxy.url}/api/configuration-db/update-dealer-configuration`,
                {
                    configurationName: title,
                    model,
                    configurationUrl: configUrl,
                    force: true
                }
            )
            .pipe(
                catchError((err) => throwError(() => new Error(err.statusText)))
            );
    }

    getSavedConfigurations$(
        page: number,
        pageSize: number,
        sortBy: string,
        sortOrder: string,
        searchTerm?: string
    ): Observable<SavedConfigurationPage> {
        let params = new HttpParams();

        params = params.append('page', page);
        params = params.append('itemsPerPage', pageSize);
        params = params.append('sortOrder', sortOrder);
        params = params.append('sortBy', sortBy);
        if (searchTerm) {
            params = params.append('search', searchTerm);
        }

        return this.httpClient
            .get(
                `${environment.appConfig.dataProxy.url}/api/configuration-db/get-dealer-configuration-list`,
                { params }
            )
            .pipe(switchMap((response) => this.toConfigurationsPage(response)));
    }

    deleteSavedConfiguration$(savedConfigId: string): Observable<unknown> {
        let params = new HttpParams();
        params = params.append('id', savedConfigId);

        return this.httpClient
            .delete(
                `${environment.appConfig.dataProxy.url}/api/configuration-db/delete-dealer-configuration`,
                {
                    params
                }
            )
            .pipe(shareReplay(1));
    }

    async toConfigurationsPage(response: any): Promise<SavedConfigurationPage> {
        const savedConfigurations = await Promise.all(
            response.data.map(async (config) =>
                this.toSavedConfiguration(config)
            )
        );
        return {
            page: response.page,
            pageSize: response.perPage,
            totalPages: response.totalPages,
            savedConfigurations
        };
    }

    async toSavedConfiguration(savedConfiguration: {
        configurationName: string;
        configurationUrl: string;
        date: string;
        dealerId: string;
        id: string;
        model: string;
    }): Promise<SavedConfiguration> {
        const formatDate = this.datepipe.transform(
            savedConfiguration.date,
            'dd/MM/yy'
        );

        let modelProductInfo: ProductInfo | undefined;
        try {
            modelProductInfo = await firstValueFrom(
                this.productDataService.getProductInfo$(
                    savedConfiguration.model
                )
            );
        } catch (error) {
            console.error(
                `Failed getting productInfo for id ${savedConfiguration.model}`,
                error
            );
        }

        return {
            id: savedConfiguration.id,
            dealerId: savedConfiguration.dealerId,
            image: this.getImageURL(savedConfiguration.configurationUrl),
            model: modelProductInfo
                ? this.getModelNiceName(
                      modelProductInfo.modelId,
                      modelProductInfo.id
                  )
                : 'N/A',
            name: savedConfiguration.configurationName,
            date: formatDate ?? '',
            configurationURL: savedConfiguration.configurationUrl
        };
    }

    // FIXME: Replace using StaticRendererService.getRenderingSrcset$ to obtain image
    getImageURL(configUrl: string): string {
        const countryCode = configUrl.split('/')[3];
        const modelCode = this.getModelCode(configUrl);
        const options = configUrl.split('/')[6];
        const resolution = '960';

        const deserializedData =
            this.serializerService.deserializeDataDontThrowErr<
                CompleteLocationSessionData | undefined
            >(decodeURIComponent(options));

        if (deserializedData) {
            deserializedData.camera = {
                id: 'BS_Config_Ext_1',
                options: {}
            };

            deserializedData.environment = {
                id: 'Studio',
                options: {
                    night: false
                }
            };

            return `${
                environment.appConfig.imageProxy.url
            }/${countryCode}/${modelCode}/${resolution}/${this.serializerService.serializeData<CompleteLocationSessionData>(
                deserializedData
            )}`;
        }
        // use current camera and environment on fallback
        return `${environment.appConfig.imageProxy.url}/${countryCode}/${modelCode}/${resolution}/${options}`;
    }

    getModelCode = (configUrl: string): string => {
        const splitURL = configUrl.split('/');
        return splitURL[5];
    };

    getModelNiceName = (modelId: string, productId: string): string =>
        this.labelHelperService.getProductName(modelId, productId);

    /**
     * Open the edit view for the currently active configuration session.
     * This assumes that this method is called from a context, where a
     * configuration session is active (productId, sessionId and configurationDetailId
     * are set).
     * Basic error-handling is performed by notifying the user about a potential
     * error that occurred.
     */
    openEditView$() {
        this.uiNotificationService.showAutohideNotification(
            translate('ORDER_MANAGEMENT.NOTIFICATION.OPEN_CONFIG_IN_OM')
        );
        return combineLatest([
            this.configurationSessionInfoService.getActiveProductId$(),
            this.cpqSessionHandlerService.getSessionId$(),
            this.cpqSessionHandlerService.getConfigurationDetailId$()
        ]).pipe(
            take(1),
            switchMap(([productId, sessionId, configurationDetailId]) => {
                if (!productId || !sessionId || !configurationDetailId) {
                    throw new IllegalStateError(
                        'Unable to get product id and/or session id and/or configurationdetail id.'
                    );
                }
                const model = getModelCodeForDerivateCode(productId);
                this.setExistingConfigDetailId(configurationDetailId ?? '');
                this.setExistingModelCode(model ?? '');
                return this.postConfigure$(model, sessionId).pipe(
                    withLatestFrom(
                        this.uiContextHasAuthorizedOnlyOptionsSelected$()
                    ),
                    switchMap(([orderConfiguration, uiContextHasQOptions]) => {
                        // check if ui-context has q-options enabled
                        // check if q-commission (QCommission) attribute is available in config
                        // if q-commission is available AND no q-option is selected, re-post settings QCommission to false

                        const existingQComissionAttribute =
                            orderConfiguration.formGroups.find(
                                (formGroup) =>
                                    formGroup.formOption?.formGroupTitle ===
                                    'QCommission'
                            )?.formOption;

                        if (!existingQComissionAttribute) {
                            // no q-comission attribute available
                            return of(orderConfiguration);
                        }

                        if (!uiContextHasQOptions) {
                            // set q-comission attribute to false
                            return this.postConfigure$(
                                model,
                                sessionId,
                                existingQComissionAttribute.id,
                                'False'
                            );
                        }

                        // keep everything as-is
                        return of(orderConfiguration);
                    })
                );
            }),
            tap({
                next: (currentConfig) => {
                    this.setExistingConfig$(currentConfig);
                    this.router.navigate([
                        `INT/${ROUTE_ORDER_MANAGEMENT}/${ROUTE_ORDER_EDIT}`
                    ]);
                },
                error: (error) => {
                    const errorTranslatedTxt =
                        this.i18nService.translateWithFallback(
                            `ORDER_MANAGEMENT.NOTIFICATION.FAILED_START_CONFIG_SESSION`,
                            'Please try again.'
                        );
                    if (error instanceof OrderError) {
                        this.errorHandlerService.showErrorMessage(
                            () => `${error.message}. ${errorTranslatedTxt}`,
                            error
                        );
                    } else {
                        this.errorHandlerService.showErrorMessage(
                            () => `${error}. ${errorTranslatedTxt}`,
                            error
                        );
                    }
                }
            })
        );
    }

    getSelectedAccessories$(): Observable<string[] | undefined> {
        return this.getUiContextOptionGroupsSnapshot$().pipe(
            map((optionGroups) =>
                optionGroups?.filter(
                    (optionGroup) =>
                        optionGroup.name ===
                            environment.appConfig.configuration
                                .identifierSummary ||
                        optionGroup.name ===
                            environment.appConfig.configuration
                                .identifierAccessories
                )
            ),
            map((filteredOptionGroups) => {
                if (!filteredOptionGroups) {
                    return [];
                }
                return this.nodeLookupService
                    .collectNodes<ExtendedUiOptionCode>(
                        (node) => isExtendedUiOptionCode(node) && node.selected,
                        filteredOptionGroups
                    )
                    .map((code) => code.code);
            })
        );
    }

    initCatchOpportunityIdFromUrl() {
        this.getOpportunityIdSearchParamFromUrl$()
            .pipe(
                filter((opportunityId) => !!opportunityId),
                distinctUntilChanged(),
                untilDestroyed(this)
            )
            .subscribe(this.existingOpportunityIdSource);
    }

    private getOpportunityIdSearchParamFromUrl$(): Observable<
        string | undefined
    > {
        return this.router.events.pipe(
            filter(
                (event): event is NavigationEnd =>
                    event instanceof NavigationEnd
            ),
            startWith(undefined),
            map(
                () =>
                    // extract opportunityId search-param
                    this.router.routerState.snapshot.root.queryParams
                        .opportunityId
            ),
            distinctUntilChanged()
        );
    }

    private uiContextHasAuthorizedOnlyOptionsSelected$() {
        return combineLatest([
            this.getUiContextOptionGroupsSnapshot$(),
            this.productConfigurationSessionService
                .getConfigurationInfo$()
                .pipe(
                    timeout(0),
                    switchMap((configurationInfo) => {
                        if (!configurationInfo || !configurationInfo.country) {
                            return of([]);
                        }

                        return this.httpClient.get<ConfigModel[]>(
                            `${environment.appConfig.ruler.url}/options/authorized/${configurationInfo.productId}/${configurationInfo.country}`
                        );
                    }),
                    catchError(() => of([]))
                )
        ]).pipe(
            map(([uiOptionGroups, authorizedOnlyOptions]) => {
                const authorizedOnlyNodes =
                    this.nodeLookupService.findNodesByConfigModelEntries(
                        authorizedOnlyOptions,
                        uiOptionGroups ?? []
                    );

                return !!authorizedOnlyNodes.find((node) => node.selected);
            }),
            take(1)
        );
    }

    private getUiContextOptionGroupsSnapshot$() {
        return this.productConfigurationSessionService.getOptionGroups$().pipe(
            timeout(0), // in case is no configuration session is active no result is returned
            catchError(() => of(undefined)),
            take(1)
        );
    }
}
